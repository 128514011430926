<template>
    <div class="index-wrapper">
        <!-- 正式环境 -->
        <iframe id="jfsc" ref="jfsc" v-if="reloadIframe"
            :src="`https://mallscore.haoyouzhushou.com/#/?from=mydj&phone=${phone}&token=${token}&productCode=${productCode}&order_activeName=${order_activeName}&signStatus=${signStatus}&taskStatus=${taskStatus}`"
            width="100%" height="716px" frameborder="0"></iframe>

        <!-- 测试环境 -->
        <!-- <iframe id="jfsc" ref="jfsc" v-if="reloadIframe"
            :src="`https://testbuywater.ggzuhao.com/#/?from=mydj&phone=${phone}&token=${token}&productCode=${productCode}&order_activeName=${order_activeName}&signStatus=${signStatus}&taskStatus=${taskStatus}`"
            width="100%" height="716px" frameborder="0"></iframe> -->

        <!-- 本地调试 -->
        <!-- <iframe id="jfsc" ref="jfsc" v-if="reloadIframe"
            :src="`http://localhost:8081/#/?from=mydj&phone=${phone}&token=${token}&productCode=${productCode}&order_activeName=${order_activeName}&signStatus=${signStatus}&taskStatus=${taskStatus}`"
            width="100%" height="716px" frameborder="0"></iframe> -->
    </div>
</template>
  
<script>
import { mapGetters } from "vuex";
import { md5 } from "@/util/crypto";
import { GetIntegralTasks, GetCheckInLog, CompleteTask } from '@/api'

export default {
    computed: {
        ...mapGetters([
            "userInfo",
            "isLogin",
        ]),
    },
    data() {
        return {
            phone: "",
            token: "",
            timer: "",
            productCode: "",
            order_activeName: "",
            reloadIframe: 1,
            signStatus: false,
            taskStatus: false,
        };
    },
    created() {
        if (this.userInfo && this.userInfo.State !== 0) {
          this.$message({
            message: "您存在严重违规行为，禁止前往积分商城",
            type: "error",
          });
          this.$router.push('/index')
          return false;
        }
        eventBus.$on("reloadIframe", (toggle) => {
            this.reloadIframe = 0
            setTimeout(() => {
                this.reloadIframe = 1
            }, 200)
        });
        if (this.isLogin && this.userInfo) {
            this.phone = this.userInfo.Phone;
            let str = this.phone + "-THB-WEB-FOR-CLIENT-";
            this.token = md5(str).toUpperCase();

            this._GetIntegralTasks();
            this._GetCheckInLog();
        }
    },
    mounted() {
        window.addEventListener("message", this.messageEv);
        if (this.$route.query.ProductCode) {
            this.productCode = this.$route.query.ProductCode
        }
        if (this.$route.query.order_activeName) {
            this.order_activeName = this.$route.query.order_activeName
        }
    },
    beforeDestroy() {
        window.removeEventListener('message', this.messageEv);
    },
    methods: {
        // 获取7天签到数据
        async _GetCheckInLog() {
            let params = {
                consumerId: this.userInfo.ConsumerId,
            }
            let res = await GetCheckInLog(params);
            if (res.StatusCode == 200) {
                let signInList = res.Object;
                if (signInList.length) {
                    signInList.map(item => {
                        if (item.CreateTime.includes(new Date().toISOString().split('T')[0])) {
                            this.signStatus = true
                        }
                    })
                    console.log(this.signStatus)
                }
            }
        },
        // 获取积分任务
        async _GetIntegralTasks() {
            let params = {
                consumerId: this.userInfo && this.userInfo.ConsumerId,
                isShow: 1
            }
            let res = await GetIntegralTasks(params)
            if (res.StatusCode == 200) {
                let taskList = res.Object;
                if (taskList.length) {
                    taskList.map(item => {
                        if (!(item.ConsumerIntegral < item.Integrallimit)) {
                            this.taskStatus = true;
                        } else {
                            this.taskStatus = false;
                        }
                    })
                    console.log(this.taskStatus)
                }
            }
        },
        messageEv(e) {
            if (e.data && e.data.str == 'login') {
                if (this.isLogin && !this.userInfo.Phone) {
                    eventBus.$emit('bindPhone')
                } else {
                    eventBus.$emit("getQrCode");
                }
            }
            if (e.data && e.data.url == '/signin') {
                this.$router.push('/signin')
            }
            if (e.data && e.data.url == '/eSports') {
                this.$router.push('/eSports')
            }
            if (e.data && e.data.url == '/home') {
                this.$router.push('/home')
            }
        }
    }
};
</script>
  
<style lang="scss" scoped>
.index-wrapper {
    height: 716px;
    overflow: hidden;
    background: $white-col;
}
</style>